import React from 'react';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      {/* Header */}
      <header className="w-full min-h-screen bg-very-dark-blue flex items-center justify-center">
        <nav className="container mx-auto text-center">
          <div className="text-left">
            <h1 className="text-5xl font-bold text-white mb-4">Studio Moonbutt</h1>
            <button className="mt-6 bg-yellow-500 text-gray-900 font-semibold py-3 px-6 rounded-lg shadow-lg hover:bg-yellow-600 transition duration-300">
              Join our Mailing List
            </button>
          </div>           
        </nav>
      </header>

      {/* Main Content and Footer */}
      <div className="flex-grow min-h-screen flex flex-col">
        <main className="flex-grow">
          {/* Hero Section */}
          <section className="container mx-auto text-center py-20">
            <h2 className="text-5xl font-extrabold mb-4">Black Fowl Ritual</h2>
            <h3 className="text-lg mb-8">A dark take on "take-that" card games</h3>
            <button className="bg-yellow-500 text-gray-900 font-semibold py-3 px-6 rounded-lg shadow-lg hover:bg-yellow-600 transition duration-300">Follow our Journey</button>
          </section>

          {/* Features Section */}
          <section id="features" className="container mx-auto py-16">
            <h3 className="text-4xl font-bold mb-8 text-center">The Flock is coming to your next Game Night</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
              <div className="bg-white text-black p-8 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Conjure birds</h3>
                <p>Hold hands (or don't) and conjure your murder of Crows, Vultures, Owls, and Ravens</p>
              </div>
              <div className="bg-white text-black p-8 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Come together</h3>
                <p>Supports 2-4 players making face-to-face connections 'in' again</p>
              </div>
              <div className="bg-white text-black p-8 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">Quick Gameplay</h3>
                <p>5-15 minute games. Quick enough to take turns saying <span className="font-semibold">"Fuck You"</span> within the hour</p>
              </div>
            </div>
          </section>
        </main>

        {/* Footer */}
        <footer className="bg-yellow-500 py-6">
          <div className="container mx-auto text-center text-black">
            <p>&copy; 2024 Studio Moon Butt. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default LandingPage;
